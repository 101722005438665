import { css } from 'linaria';
import { styled } from 'linaria/react';
import React from 'react';
import { theme } from '../../components/Theme';
import { ProductGrid } from '../../components/ProductGrid';
import ProductCarousel from '../../components/ProductCarousel';
import Heading from '../../components/ui/Heading';
import MaxWidth from '../../components/Layout/MaxWidth';
import FullWidth from '../../components/Layout/FullWidth';
import { Above } from '@jetshop/ui/Breakpoints';

const Container = styled('div')`
  padding-top: 28px;
  padding-bottom: 28px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: 50%;
    width: 100vw;
    transform: translateX(-50%);
    top: 0;
    bottom: 0;
    background: ${theme.colorScheme.superlightgrey};
  }
  ${theme.below.sm} {
    padding-top: 32px;
    padding-bottom: 4px;
  }
`;

// The horizontal scroll is styled in a way that on any resolution smaller than 'lg', a part of the second/third item can be seen, indicating it is a horizontally scrollable container.
export const productGrid = css`
  flex-wrap: nowrap !important;
  ${theme.below.md} {
    overflow-x: auto;
    .product-card {
      width: 300px;
      flex-shrink: 0;
      max-width: 40vw;
    }
  }
  ${theme.below.sm} {
    .product-card {
      max-width: 60vw;
      width: 340px;
    }
  }
  ${theme.below.xs} {
    .product-card {
      max-width: 75vw;
    }
  }

  @supports (display: grid) {
    grid-template-columns: repeat(4, 1fr);
    margin: 0;
    ${theme.below.md} {
      grid-template-columns: repeat(10, 1fr);
    }
  }
`;

const CategoryProducts = ({ linkLabel, title, category, ...rest }) => {
  const { primaryRoute, products } = category?.value || {};
  return !products?.result?.length ? null : (
    <Container>
      {title?.value ? (
        <MaxWidth>
          <Heading level="2" asLevel={4} underline>
            {title.value}
          </Heading>
        </MaxWidth>
      ) : null}
      <Above breakpoint="xl">
        {matches =>
          matches ? (
            <MaxWidth className="above-xl">
              <ProductGrid
                products={products.result}
                className={productGrid}
                widths={{
                  xl: linkLabel?.value ? 1 / 6 : 1 / 5
                }}
                readMore={
                  linkLabel?.value && {
                    text: linkLabel?.value,
                    to: primaryRoute.path
                  }
                }
              />
            </MaxWidth>
          ) : (
            <FullWidth className="below-xl">
              <ProductCarousel
                products={products.result}
                className={productGrid}
                readMore={
                  linkLabel?.value && {
                    text: linkLabel?.value,
                    to: primaryRoute.path
                  }
                }
              />
            </FullWidth>
          )
        }
      </Above>
    </Container>
  );
};

export default CategoryProducts;
